import { Districts } from '../../components/Districts'

export function Home() {



  return (
    <div id="page-home">
      <h1>Home Page</h1>
      <Districts />
    </div>

  )
}
